<template>
  <div>
    <div class="content-wrapper">
      <template v-for="content in contentsList">
        <div
          :id="`content-${content.id}`"
          class="content-div"
          :key="content.id"
        >
          <div class="rich-text-content" v-html="content.content"></div>
          <div v-if="allows_crud && content.id" class="text-center">
            <ButtonSmall
              tabindex="-1"
              :tooltip_text="`Editar Contenido`"
              :click_button="
                () => $bvModal.show(`edit-content-modal-${content.id}`)
              "
              :class="``"
              :icon="'square'"
            ></ButtonSmall>
            <!-- <button-edit
              tabindex="-1"
              @click="$bvModal.show(`edit-content-modal-${content.id}`)"
              v-b-tooltip.v-secondary.noninteractive="'Editar Contenido'"
            >
            </button-edit> -->
            <ButtonSmall
              tabindex="-1"
              :click_button="() => deleteContent(content.id)"
              :tooltip_text="`Eliminar Contenido`"
              :class="``"
              :icon="'trash'"
            ></ButtonSmall>
            <!-- <button-delete
              tabindex="-1"
              @click="deleteContent(content.id)"
              v-b-tooltip.v-secondary.noninteractive="'Eliminar Contenido'"
            ></button-delete> -->
            <b-modal
              :id="`edit-content-modal-${content.id}`"
              title="Editar Contenido"
              size="lg"
              hide-footer
            >
              Copie y edite los contenidos por separado
              <NewRichTextEditor
                :Object="content"
                :Text="content.content"
                @save="updateContent"
                @close="$bvModal.hide(`edit-content-modal-${content.id}`)"
              ></NewRichTextEditor>
            </b-modal>
          </div>
        </div>
      </template>
    </div>
    <div v-if="allows_crud" class="text-center">
      <ButtonMedium
        :tooltip_text="`Agregue los Contenido por separado`"
        :click_button="
          () => $bvModal.show(`create-content-modal-${eCMacro_id}`)
        "
        :text_button="`Agregar`"
        :icon="'plus'"
        :variant="'primary'"
      >
      </ButtonMedium>
      <!-- <b-button
        @click="$bvModal.show(`create-content-modal-${eCMacro_id}`)"
        variant="secondary"
        size="sm"
        v-b-tooltip.v-secondary.noninteractive="
          'Agregue los Contenido por separado'
        "
      >
        Agregar Contenido
      </b-button> -->
      <b-modal
        :id="`create-content-modal-${eCMacro_id}`"
        title="Crear Contenido"
        size="lg"
        hide-footer
      >
        <NewRichTextEditor
          @save="createContent"
          @close="$bvModal.hide(`create-content-modal-${eCMacro_id}`)"
        ></NewRichTextEditor>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { toast } from "@/utils/utils";
export default {
  name: "ECMacroContent",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
  },
  props: {
    eCMacro_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      evaluation_criteria_contents: null,
    };
  },
  computed: {
    contentsList() {
      if (!this.evaluation_criteria_contents) return [];
      return this.evaluation_criteria_contents.filter(
        (x) => x.evaluation_criteria_macro == this.eCMacro_id
      );
    },
  },
  methods: {
    createContent(text) {
      // if (!args[0] || args[0] == "") return;
      if (text != "") {
        let item = {
          evaluation_criteria_macro: this.eCMacro_id,
          content: text,
        };
        this.$restful
          .Post(`/teaching/evaluation-criteria-macro-content/`, item)
          .then((response) => {
            this.evaluation_criteria_contents.push(response);
          });
      } else toast("No se puede guardar si está vacío.");
    },
    updateContent(content, text) {
      if (text != "") {
        const payload = {
          id: content.id,
          item: {
            content: text,
          },
        };
        this.$restful
          .Patch(
            `/teaching/evaluation-criteria-macro-content/${payload.id}/`,
            payload.item
          )
          .then((response) => {
            const index = this.evaluation_criteria_contents.findIndex(
              (x) => x.id == response.id
            );
            if (index != -1)
              this.evaluation_criteria_contents.splice(index, 1, response);
            else this.evaluation_criteria_contents.push(response);
          });
      } else toast("No se puede guardar si está vacío.");
    },
    deleteContent(id) {
      this.$restful
        .Delete(`/teaching/evaluation-criteria-macro-content/${id}/`)
        .then(() => {
          const index = this.evaluation_criteria_contents.findIndex(
            (x) => x.id == id
          );
          if (index != -1) this.evaluation_criteria_contents.splice(index, 1);
        });
    },
  },
  created() {
    this.$restful
      .Get(
        `/teaching/evaluation-criteria-macro-content/?evaluation_criteria_macro=${this.eCMacro_id}`
      )
      .then((response) => {
        this.evaluation_criteria_contents = response;
      });
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.content-div {
  width: 100%;
  padding: 0.4em;
  margin-bottom: 0.3em;
  text-align: justify;
  border-bottom: 1px solid var(--secondary-color);
}
.content-div:last-of-type {
  border-bottom: none !important;
}
.content-wrapper {
  margin: 0;
  padding: 0;
}
</style>